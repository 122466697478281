import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import axios from "axios";

import config from "./components/Loader/api";

import walletLogo from "./logos/apple_wallet.png";
import telegramLogo from "./logos/telegram.svg";
import hand from "./logos/hand-left.svg";
import mainPage from "./logos/main-page-img.svg";

import "./App.css";

import { Loader } from "./components/Loader";
import { Footer } from "./components/Footer/Footer";
import { AppHeader } from "./components/Header/header";

function App() {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [walletSrc, setWalletSrc] = useState("");
  const [searchParams] = useSearchParams();
  const search = searchParams.get("search");
  const requestCode = searchParams.get("requestCode");

  const submit = async () => {
    setIsLoading(true);
    try {
      const resp = await axios.get(
        `${config.apiUrl}/archive?search=${search}&requestCode=${requestCode}`,
        { responseType: "blob" }
      );
      setWalletSrc(window.URL.createObjectURL(resp.data));
    } catch (err: any) {
      let error = "";
      switch (err?.response?.status) {
        case 500:
          error = "Что то пошло не так";
          break;
        case 404:
          error = "Неверный код или номер телефона";
          break;
        case 400:
          error = "Неверный формат";
          break;
        case 401:
          error = "Неверный код";
          break;
      }
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (search && requestCode) {
      submit();
    }
  }, [search, requestCode]);

  return (
    <div className="min-h-screen bg-gray-100 text-gray-900 flex flex-col items-center p-6">
      <header className="w-full max-w-3xl bg-white shadow-lg rounded-2xl p-6 text-center">
        <img
          src="/logo.png"
          alt="Basquiat Coffee Logo"
          className="mx-auto w-20 h-20 mb-2"
        />
        <h1 className="text-3xl font-bold">Basquiat Coffee</h1>
        <i className="text-gray-600 mt-2">Coffee and art</i>
      </header>

      <main className="w-full max-w-3xl bg-white shadow-lg rounded-2xl p-6 mt-6">
        <section>
          <h2 className="text-2xl font-semibold">О нас</h2>
          <p className="text-gray-700 mt-2">
            Basquiat Coffee — это место, где искусство встречается с ароматом
            свежесваренного кофе. Мы предлагаем лучшие сорта зерен и уютную
            атмосферу.
          </p>
        </section>

        <section className="mt-6">
          <h2 className="text-2xl font-semibold">Меню</h2>
          <div className="grid grid-cols-2 gap-4 mt-2 text-gray-700">
            <div>
              <h3 className="text-xl font-bold">Кофе</h3>
              <ul>
                <li>☕ Эспрессо - 150₽</li>
                <li>☕ Латте - 200₽</li>
                <li>☕ Капучино - 200₽</li>
                <li>☕ Раф - 300₽</li>
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-bold">Еда</h3>
              <ul>
                <li>🍰 Чизкейк - 250₽</li>
                <li>🍰 Сырники - 250₽</li>
              </ul>
            </div>
          </div>
        </section>

        <section className="mt-6">
          <h2 className="text-2xl font-semibold">Картинная галерея</h2>
          <p className="text-gray-700 mt-2">
            В нашей кофейне вы найдете уютную картинную галерею, где
            представлены работы современных художников. Наслаждайтесь искусством
            вместе с чашечкой ароматного кофе.
          </p>
        </section>

        <section className="mt-6">
          <h2 className="text-2xl font-semibold">Карта лояльности</h2>
          <p className="text-gray-700 mt-2 flex items-center">
            Чтобы получить карту лояльности, обратитесь к нашему боту в{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://t.me/basquiat_wallet_provider_bot"
              className="telegram_link desctop"
            >
              <img alt="telegram" src={telegramLogo} />
            </a>
          </p>

          <div className="mt-4 flex">
            <a
              href="https://t.me/basquiat_wallet_provider_bot"
              className="mr-2"
            >
              <img
                src="/add_apple_wallet_bage.svg"
                alt="Add to Apple Wallet"
                className="h-10"
              />
            </a>

            <a href="https://t.me/basquiat_wallet_provider_bot">
              <img
                src="/add_google_wallet_badge.svg"
                alt="Add to Google Wallet"
                className="h-10"
              />
            </a>
          </div>
        </section>

        <section className="mt-6">
          <h2 className="text-2xl font-semibold">Контакты</h2>
          <p className="text-gray-700 mt-2">
            📍 Адрес: Новочеркасск, ул. Московская 67/2
          </p>
          <p className="text-gray-700 mt-2">
            📍 Адрес: Новочеркасск, ул. Просвещения 127/109
          </p>
          <p className="text-gray-700 mt-2">📞 Телефон: +7 (960) 460-09-99</p>
        </section>
      </main>
    </div>
  );
}

export default App;
